* {
  padding: 0;
  margin: 0;
}

html {
  height: 100%;
  display: flex;
}

body {
  display: flex;
  flex-grow: 1;
  font-family: sans-serif;
}

#root {
  display: flex;
  flex-grow: 1;
}

/* https://cssloaders.github.io/ */
.loader {
  width: 40px;
  height: 6px;
  background:
    radial-gradient(circle 3px at 3px center, #000 100%, transparent 0),
    radial-gradient(circle 3px at 3px center, #000 100%, transparent 0);
  background-size: 6px 6px;
  background-repeat: no-repeat;
  position: relative;
  animation: ballX 1s linear infinite;
}

.loader:before {
  content: "";
  position: absolute;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background:#000;
  inset:0;
  margin:auto;
  animation: moveX 1s cubic-bezier(0.5,80,0.5,-80) infinite;
}

@keyframes ballX {
  0%,25%,50%,75%, 100% { background-position: 25% 0,75% 0 }
  40% { background-position: 25% 0,85% 0}
  90% { background-position: 15% 0,75% 0 }
}

@keyframes moveX {
  100% {transform:translate(0.15px)}
}
